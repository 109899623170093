import React, { useEffect } from 'react';
import { Box } from '@mui/material';

function BuyMeCoffeeWidget() {
  useEffect(() => {
    const loadWidget = () => {
      // Create container if it doesn't exist
      let container = document.getElementById('bmc-container');
      if (!container) {
        container = document.createElement('div');
        container.id = 'bmc-container';
        document.body.appendChild(container);
      }

      // Create the button
      const button = document.createElement('a');
      button.href = "https://www.buymeacoffee.com/tawhidj";
      button.target = "_blank";
      button.rel = "noopener noreferrer";
      button.className = "bmc-button";
      
      // Style the button
      button.style.cssText = `
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        background-color: #5F7FFF;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        font-family: Arial, sans-serif;
        font-weight: bold;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 10000;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        transition: all 0.3s ease;
      `;

      // Add hover effect
      button.onmouseover = () => {
        button.style.transform = 'translateY(-2px)';
        button.style.boxShadow = '0 6px 12px rgba(0,0,0,0.15)';
      };
      button.onmouseout = () => {
        button.style.transform = 'translateY(0)';
        button.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
      };

      // Add content
      button.innerHTML = `
        <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" 
             alt="Buy me a coffee" 
             style="height: 28px; width: 28px; margin-right: 8px;">
        <span>Buy me a coffee</span>
      `;

      container.appendChild(button);
    };

    loadWidget();

    return () => {
      const container = document.getElementById('bmc-container');
      if (container) {
        container.remove();
      }
    };
  }, []);

  return null;
}

export default BuyMeCoffeeWidget; 