import React, { forwardRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';

const deviceTemplates = {
  browser: {
    padding: '32px 16px 16px',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '8px',
      left: '16px',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#ff5f57',
      boxShadow: '20px 0 0 #ffbd2e, 40px 0 0 #28c940',
      zIndex: 2,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '32px',
      backgroundColor: '#f5f5f5',
      borderRadius: '8px 8px 0 0',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      zIndex: 1,
    },
  },
  browserDark: {
    padding: '32px 16px 16px',
    borderRadius: '8px',
    backgroundColor: '#1E1E1E',
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '8px',
      left: '16px',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#ff5f57',
      boxShadow: '20px 0 0 #ffbd2e, 40px 0 0 #28c940',
      zIndex: 2,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '32px',
      backgroundColor: '#252525',
      borderRadius: '8px 8px 0 0',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      zIndex: 1,
    },
  },
};

const ImagePreview = forwardRef(({ image, options }, ref) => {
  const [imageDimensions, setImageDimensions] = useState(null);

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.onload = () => {
        const maxWidth = options.canvasWidth * 0.9;
        const maxHeight = options.canvasHeight * 0.9;

        const widthRatio = maxWidth / img.width;
        const heightRatio = maxHeight / img.height;
        const ratio = Math.min(widthRatio, heightRatio);

        const initialWidth = img.width * ratio;
        const scalePercentage = (options.imageSize || 300) / 300;
        const finalWidth = Math.min(initialWidth * scalePercentage, maxWidth);

        setImageDimensions({
          width: finalWidth,
          height: (finalWidth * img.height) / img.width
        });
      };
      img.src = image;
    }
  }, [image, options.imageSize, options.canvasWidth, options.canvasHeight]);

  const getBorderColorWithOpacity = (hex, opacity = 1) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const getTemplateStyles = () => {
    if (!options.template || options.template === 'none') {
      return {};
    }
    return deviceTemplates[options.template] || {};
  };

  return (
    <Box 
      ref={ref}
      sx={{
        width: options.canvasWidth,
        height: options.canvasHeight,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: `${options.borderRadius}px`,
        border: `${options.borderWidth}px solid ${options.borderColor}`,
        boxShadow: `${options.shadowSpread}px ${options.shadowSpread}px ${options.shadowBlur}px ${options.shadowColor}`,
        background: options.gradient,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <Box
        sx={{
          ...getTemplateStyles(),
          position: 'relative',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            backgroundColor: options.template ? '#1E1E1E' : 'transparent',
            borderRadius: options.template ? '0 0 8px 8px' : 0,
            padding: options.template ? 1 : 0,
          }}
        >
          <Box
            component="img"
            src={image}
            alt="Preview"
            sx={{
              width: imageDimensions?.width || 'auto',
              height: imageDimensions?.height || 'auto',
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              display: 'block',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
});

export default ImagePreview; 