import React from 'react';
import { Box } from '@mui/material';

function Logo() {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center',
        mr: 1
      }}
    >
      <img 
        src="/logoNavbar.png" 
        alt="ScreenTweak Logo"
        style={{
          height: '32px',
          width: '32px',
          marginRight: '8px',
          borderRadius: '8px',
          objectFit: 'cover',
          padding: '2px',
        }}
      />
    </Box>
  );
}

export default Logo; 