import React from 'react';
import {
  Box,
  Slider,
  Typography,
  Select,
  MenuItem,
  TextField,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaletteIcon from '@mui/icons-material/Palette';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import DevicesIcon from '@mui/icons-material/Devices';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import CodeIcon from '@mui/icons-material/Code';
import SettingsIcon from '@mui/icons-material/Settings';
import PhotoSizeSelectActual from '@mui/icons-material/PhotoSizeSelectActual';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import WebIcon from '@mui/icons-material/Web';

const gradients = {
  sunset: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  ocean: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  forest: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)',
  lavender: 'linear-gradient(45deg, #9C27B0 30%, #E1BEE7 90%)',
  midnight: 'linear-gradient(to right, #232526, #414345)',
  cosmic: 'linear-gradient(to right, #00dbde, #fc00ff)',
  mojave: 'linear-gradient(to right, #ee0979, #ff6a00)',
  aurora: 'linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d)',
  emerald: 'linear-gradient(to right, #134E5E, #71B280)',
  royal: 'linear-gradient(to right, #141E30, #243B55)',
  deepSpace: 'linear-gradient(to right, #000428, #004e92)',
  cotton: 'linear-gradient(to right, #D4145A, #FBB03B)',
  neonBlue: 'linear-gradient(to right, #00c6ff, #0072ff)',
  purpleDream: 'linear-gradient(to right, #5f2c82, #49a09d)',
  synthwave: 'linear-gradient(to right, #FC466B, #3F5EFB)',
  paradise: 'linear-gradient(to right, #3494E6, #EC6EAD)',
  frost: 'linear-gradient(to right, #000428, #004e92)',
  cherry: 'linear-gradient(to right, #EB3349, #F45C43)',
  nordLight: 'linear-gradient(to right, #4ECDC4, #556270)',
  twilight: 'linear-gradient(to right, #0F2027, #203A43, #2C5364)',
  sahara: 'linear-gradient(to right, #F7971E, #FFD200)',
  rainforest: 'linear-gradient(to right, #11998e, #38ef7d)',
  ultraviolet: 'linear-gradient(to right, #654ea3, #eaafc8)',
  volcano: 'linear-gradient(to right, #870000, #190A05)',
  arctic: 'linear-gradient(to right, #83a4d4, #b6fbff)',
  cyberpunk: 'linear-gradient(to right, #f953c6, #b91d73)',
  matrix: 'linear-gradient(to right, #000000, #0f9b0f)',
  sunset2: 'linear-gradient(to right, #FF512F, #F09819)',
  moonlight: 'linear-gradient(to right, #0F2027, #203A43, #2C5364)',
  candy: 'linear-gradient(to right, #FF69B4, #FF1493)',
  galaxy: 'linear-gradient(to right, #0f0c29, #302b63, #24243e)',
  coral: 'linear-gradient(to right, #FF7F50, #FF6B6B)',
  mint: 'linear-gradient(to right, #00B09B, #96C93D)',
  wine: 'linear-gradient(to right, #8E2DE2, #4A00E0)',
};

// Add back the imageTemplates object
const imageTemplates = {
  none: 'None',
  browser: 'Browser Window (Light)',
  browserDark: 'Browser Window (Dark)',
};

// Separate templates for different modes
const codeTemplates = {
  code: 'Code Window',
};

// Add supported languages
const codeLanguages = {
  javascript: 'JavaScript',
  python: 'Python',
  java: 'Java',
  cpp: 'C++',
  csharp: 'C#',
  ruby: 'Ruby',
  php: 'PHP',
  swift: 'Swift',
  go: 'Go',
  rust: 'Rust',
  typescript: 'TypeScript',
  sql: 'SQL',
  html: 'HTML',
  css: 'CSS',
  json: 'JSON',
  yaml: 'YAML',
  markdown: 'Markdown',
  bash: 'Bash',
  shell: 'Shell',
};

function EditingTools({ options, onChange, mode }) {
  const handleChange = (field) => (event) => {
    onChange({
      ...options,
      [field]: event.target.value,
    });
  };

  // Helper function to convert hex color with opacity
  const getColorWithOpacity = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  // Show template selection based on mode
  const getTemplateOptions = () => {
    if (mode === 'code') {
      return codeTemplates;
    }
    return imageTemplates;
  };

  return (
    <Box sx={{
      width: 300,
      background: '#1A1A1A',
      borderRadius: 2,
      border: '1px solid rgba(255, 255, 255, 0.1)',
    }}>
      <Typography
        variant="h6"
        sx={{
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '1.25rem',
          p: 2,
          pb: 1
        }}
      >
        Editing Tools
      </Typography>

      <Accordion
        defaultExpanded
        sx={{
          background: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
          sx={{
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            minHeight: 48,
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              gap: 1,
            }
          }}
        >
          <PaletteIcon sx={{ color: '#BB86FC' }} />
          <Typography sx={{ color: '#fff' }}>Background Gradient</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            pb: 2,
            '::-webkit-scrollbar': {
              height: '8px',
            },
            '::-webkit-scrollbar-track': {
              background: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '4px',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '4px',
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.3)',
              },
            },
          }}>
            {Object.entries(gradients).map(([key, value]) => (
              <Paper
                key={key}
                onClick={() => onChange({ ...options, gradient: value })}
                sx={{
                  width: '60px',
                  height: '60px',
                  flexShrink: 0,
                  background: value,
                  cursor: 'pointer',
                  borderRadius: 2,
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  border: options.gradient === value ? '2px solid #BB86FC' : '2px solid transparent',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    p: 0.5,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'white',
                      fontSize: '8px',
                      textAlign: 'center',
                      textShadow: '0 1px 2px rgba(0,0,0,0.5)',
                      opacity: 0.8,
                    }}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          background: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
          sx={{
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            minHeight: 48,
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              gap: 1,
            }
          }}
        >
          <BorderAllIcon sx={{ color: '#BB86FC' }} />
          <Typography sx={{ color: '#fff' }}>Border Radius</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Slider
            value={options.borderRadius}
            onChange={(_, value) => onChange({ ...options, borderRadius: value })}
            min={0}
            max={50}
            valueLabelDisplay="auto"
            sx={{
              color: '#BB86FC',
              '& .MuiSlider-thumb': {
                backgroundColor: '#fff',
              },
              '& .MuiSlider-track': {
                backgroundColor: '#BB86FC',
              },
              '& .MuiSlider-rail': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
              '& .MuiSlider-valueLabel': {
                backgroundColor: '#BB86FC',
              },
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          background: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
          sx={{
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            minHeight: 48,
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              gap: 1,
            }
          }}
        >
          <BorderStyleIcon sx={{ color: '#BB86FC' }} />
          <Typography sx={{ color: '#fff' }}>Border Style</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
              <Typography sx={{ color: '#fff', mb: 1 }}>Width</Typography>
              <Slider
                value={options.borderWidth}
                onChange={(_, value) => onChange({ ...options, borderWidth: value })}
                min={0}
                max={20}
                valueLabelDisplay="auto"
                sx={{
                  color: '#BB86FC',
                  '& .MuiSlider-thumb': {
                    backgroundColor: '#fff',
                  },
                  '& .MuiSlider-track': {
                    backgroundColor: '#BB86FC',
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '& .MuiSlider-valueLabel': {
                    backgroundColor: '#BB86FC',
                  },
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ color: '#fff', mb: 1 }}>Color</Typography>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                  type="color"
                  value={options.borderColor}
                  onChange={handleChange('borderColor')}
                  sx={{
                    width: '100px',
                    '& .MuiOutlinedInput-root': {
                      height: '40px',
                    },
                    '& .MuiOutlinedInput-input': {
                      cursor: 'pointer',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.3)',
                    },
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ color: '#fff', fontSize: '0.875rem', mb: 0.5 }}>Opacity</Typography>
                  <Slider
                    value={options.borderOpacity || 1}
                    onChange={(_, value) => onChange({ ...options, borderOpacity: value })}
                    min={0}
                    max={1}
                    step={0.01}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${Math.round(value * 100)}%`}
                    sx={{
                      color: '#BB86FC',
                      '& .MuiSlider-thumb': {
                        backgroundColor: '#fff',
                      },
                      '& .MuiSlider-track': {
                        backgroundColor: '#BB86FC',
                      },
                      '& .MuiSlider-rail': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                      '& .MuiSlider-valueLabel': {
                        backgroundColor: '#BB86FC',
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Only show template selection for image mode */}
      {mode === 'image' && (
        <Accordion
          sx={{
            background: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
            sx={{
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              minHeight: 48,
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                gap: 1,
              }
            }}
          >
            <DevicesIcon sx={{ color: '#BB86FC' }} />
            <Typography sx={{ color: '#fff' }}>Device Template</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              value={options.template}
              onChange={handleChange('template')}
              fullWidth
              sx={{
                color: '#fff',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.2)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#BB86FC',
                },
                '& .MuiSelect-icon': {
                  color: '#fff',
                },
              }}
            >
              {Object.entries(getTemplateOptions()).map(([key, label]) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion
        sx={{
          background: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
          sx={{
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            minHeight: 48,
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              gap: 1,
            }
          }}
        >
          <BlurOnIcon sx={{ color: '#BB86FC' }} />
          <Typography sx={{ color: '#fff' }}>Shadow</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
              <Typography sx={{ color: '#fff', mb: 1 }}>Blur</Typography>
              <Slider
                value={options.shadowBlur || 0}
                onChange={(_, value) => onChange({ ...options, shadowBlur: value })}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                sx={{
                  color: '#BB86FC',
                  '& .MuiSlider-thumb': {
                    backgroundColor: '#fff',
                  },
                  '& .MuiSlider-track': {
                    backgroundColor: '#BB86FC',
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '& .MuiSlider-valueLabel': {
                    backgroundColor: '#BB86FC',
                  },
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ color: '#fff', mb: 1 }}>Spread</Typography>
              <Slider
                value={options.shadowSpread || 0}
                onChange={(_, value) => onChange({ ...options, shadowSpread: value })}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                sx={{
                  color: '#BB86FC',
                  '& .MuiSlider-thumb': {
                    backgroundColor: '#fff',
                  },
                  '& .MuiSlider-track': {
                    backgroundColor: '#BB86FC',
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '& .MuiSlider-valueLabel': {
                    backgroundColor: '#BB86FC',
                  },
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ color: '#fff', mb: 1 }}>Color</Typography>
              <TextField
                type="color"
                value={options.shadowColor || '#000000'}
                onChange={handleChange('shadowColor')}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '40px',
                  },
                  '& .MuiOutlinedInput-input': {
                    cursor: 'pointer',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  },
                }}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Code specific settings */}
      {mode === 'code' && (
        <>
          <Accordion
            sx={{
              background: 'transparent',
              '&:before': { display: 'none' },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
              sx={{
                borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                minHeight: 48,
                '& .MuiAccordionSummary-content': {
                  alignItems: 'center',
                  gap: 1,
                }
              }}
            >
              <CodeIcon sx={{ color: '#BB86FC' }} />
              <Typography sx={{ color: '#fff' }}>Code Language</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Select
                  value={options.codeLanguage || 'javascript'}
                  onChange={handleChange('codeLanguage')}
                  fullWidth
                  sx={{
                    color: '#fff',
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#BB86FC',
                    },
                    '& .MuiSelect-icon': {
                      color: '#fff',
                    },
                  }}
                >
                  {Object.entries(codeLanguages).map(([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              background: 'transparent',
              '&:before': { display: 'none' },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
              sx={{
                borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                minHeight: 48,
                '& .MuiAccordionSummary-content': {
                  alignItems: 'center',
                  gap: 1,
                }
              }}
            >
              <SettingsIcon sx={{ color: '#BB86FC' }} />
              <Typography sx={{ color: '#fff' }}>Code Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                  <Typography sx={{ color: '#fff', mb: 1 }}>Font Size</Typography>
                  <Slider
                    value={options.codeFontSize || 14}
                    onChange={(_, value) => onChange({ ...options, codeFontSize: value })}
                    min={10}
                    max={24}
                    step={1}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}px`}
                    sx={{
                      color: '#BB86FC',
                      '& .MuiSlider-thumb': {
                        backgroundColor: '#fff',
                      },
                      '& .MuiSlider-track': {
                        backgroundColor: '#BB86FC',
                      },
                      '& .MuiSlider-rail': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                      '& .MuiSlider-valueLabel': {
                        backgroundColor: '#BB86FC',
                      },
                    }}
                  />
                </Box>

                <Box>
                  <Typography sx={{ color: '#fff', mb: 1 }}>Show Line Numbers</Typography>
                  <Select
                    value={options.showLineNumbers ?? true}
                    onChange={handleChange('showLineNumbers')}
                    fullWidth
                    sx={{
                      color: '#fff',
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.2)',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.3)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#BB86FC',
                      },
                      '& .MuiSelect-icon': {
                        color: '#fff',
                      },
                    }}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {/* Only show image size control when in image mode */}
      {mode === 'image' && (
        <Accordion
          defaultExpanded
          sx={{
            background: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
            sx={{
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              minHeight: 48,
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                gap: 1,
              }
            }}
          >
            <PhotoSizeSelectActual sx={{ color: '#BB86FC' }} />
            <Typography sx={{ color: '#fff' }}>Image Size</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="body2" sx={{ color: '#fff' }}>
                Adjust Image Size
              </Typography>
              <Slider
                value={options.imageSize || 300}
                onChange={(_, value) => onChange({ ...options, imageSize: value })}
                min={100}
                max={800}
                valueLabelDisplay="auto"
                sx={{
                  color: '#BB86FC',
                  '& .MuiSlider-thumb': {
                    backgroundColor: '#fff',
                  },
                  '& .MuiSlider-track': {
                    backgroundColor: '#BB86FC',
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '& .MuiSlider-valueLabel': {
                    backgroundColor: '#BB86FC',
                  },
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {mode !== 'code' && (
        <Accordion
          defaultExpanded
          sx={{
            background: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
            sx={{
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              minHeight: 48,
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                gap: 1,
              }
            }}
          >
            <DevicesIcon sx={{ color: '#BB86FC' }} />
            <Typography sx={{ color: '#fff' }}>Browser Frame</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl>
                <RadioGroup
                  value={options.template || 'none'}
                  onChange={(e) => onChange({ ...options, template: e.target.value })}
                >
                  <FormControlLabel
                    value="none"
                    control={<Radio sx={{ color: '#BB86FC' }} />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CropOriginalIcon sx={{ color: '#BB86FC' }} />
                        <Typography sx={{ color: '#fff' }}>No Frame</Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="browser"
                    control={<Radio sx={{ color: '#BB86FC' }} />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <WebIcon sx={{ color: '#BB86FC' }} />
                        <Typography sx={{ color: '#fff' }}>Light Browser</Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="browserDark"
                    control={<Radio sx={{ color: '#BB86FC' }} />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <WebIcon sx={{ color: '#BB86FC' }} />
                        <Typography sx={{ color: '#fff' }}>Dark Browser</Typography>
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}

export default EditingTools;