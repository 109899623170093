import React, { forwardRef, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import CodeInput from './CodeInput';

const CodePreview = forwardRef(({ code, options, onCodeChange, onHeightChange }, ref) => {
  const previewContainerRef = useRef(null);

  // Monitor content height changes
  useEffect(() => {
    if (previewContainerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const height = Math.max(400, entry.contentRect.height + 40);
          onHeightChange?.(height);
        }
      });

      resizeObserver.observe(previewContainerRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [onHeightChange]);

  return (
    <Box 
      ref={previewContainerRef}
      sx={{
        width: options.canvasWidth,
        height: 'auto',
        minHeight: options.canvasHeight,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: `${options.borderRadius}px`,
        border: `${options.borderWidth}px solid ${options.borderColor}`,
        boxShadow: `${options.shadowSpread}px ${options.shadowSpread}px ${options.shadowBlur}px ${options.shadowColor}`,
        background: options.gradient,
      }}
    >
      <Box
        ref={ref}
        sx={{
          width: '100%',
          height: 'auto',
          minHeight: 'auto',
          background: options.gradient,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          padding: 2,
          overflow: 'visible',
        }}
      >
        <Box
          sx={{
            padding: '32px 16px 16px',
            borderRadius: '8px',
            backgroundColor: '#011627',
            position: 'relative',
            width: '100%',
            height: 'auto',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '8px',
              left: '16px',
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: '#ff5f57',
              boxShadow: '20px 0 0 #ffbd2e, 40px 0 0 #28c940',
              zIndex: 2,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '32px',
              backgroundColor: '#0b2942',
              borderRadius: '8px 8px 0 0',
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
              zIndex: 1,
            },
          }}
        >
          <Box
            className="codeContainer"
            sx={{
              height: 'auto',
              minHeight: '150px',
              transition: 'height 0.3s ease',
            }}
          >
            <CodeInput
              code={code}
              onChange={onCodeChange}
              fontSize={options.codeFontSize || 14}
              language={options.codeLanguage || 'javascript'}
              showLineNumbers={options.showLineNumbers ?? true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default CodePreview; 